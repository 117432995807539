import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { SwitchValue } from "components/special/forms";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { LocalesValues, Uploader, ImagePreview } from "components/standart";

import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";
const { api } = adminAPI.actions;

const Settings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const [settings, setSettings] = useState({});
  useEffect(() => {
    props.getSettings(
      {},
      {
        onRequest() {
          setSettings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setSettings(body);
        },
      }
    );
  }, []);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Settings" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeSettings = setOnChange(settings, setSettings);

  // аттрибуты, которые необходимо отобразить
  const attrs = [
    "phone",
    "email",
    "whatsapp",
    "instagram",
    "facebook",
    "vk",
    "telegram",
    "versionNumber",
  ];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = ["introVideo"];
  const booleanAttrs = ["allowSelectCountry"];
  const htmlAttrs = ["helpText", "stopText", "shareScreenText", "softToys", "digitalToys"];
  const filesAttrs = ["defaultImageId", "helpImageId"];

  const saveSettingsHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.saveSettings(
      {
        body: { ...settings },
      },
      {
        onSuccess(body) {
          setSettings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  function successFloat(attr) {
    return ([value = null]) => {
      setSettings({ ...settings, [attr]: value });
    };
  }

  const uploadFilesHandler =
    (attrName) =>
    ([file]) => {
      setSettings({ ...settings, [attrName]: file._id });
    };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      {settings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                {attrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <input
                          type="text"
                          name={name}
                          value={settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                {localesAttrs.map((name, index) => {
                  return (
                    <div key={index} className="form-group row">
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                        <LocalesValues.AddButton
                          className="btn-sm float-right"
                          values={settings[name]}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </label>
                      <div className="col-8">
                        <LocalesValues
                          values={settings[name]}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </div>
                    </div>
                  );
                })}

                {htmlAttrs.map((name, index) => {
                  return (
                    <div key={index} className="form-group row">
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                        <LocalesValues.AddButton
                          className="btn-sm float-right"
                          values={settings[name]}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </label>
                      <div className="col-8">
                        <LocalesValues
                          values={settings[name]}
                          isHtml={true}
                          changeValues={(values) => setSettings({ ...settings, [name]: values })}
                        />
                      </div>
                    </div>
                  );
                })}

                {booleanAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8  my-auto">
                        <SwitchValue value={settings[name]} name={name} onChange={changeSettings} />
                      </div>
                    </div>
                  );
                })}

                {filesAttrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8 my-auto">
                        {settings[name] ? (
                          <ImagePreview
                            file={{ _id: settings[name] }}
                            className="rounded-circle"
                            deleteHandler={() => {
                              setSettings({ ...settings, [name]: null });
                            }}
                          />
                        ) : (
                          "Нет"
                        )}

                        <div className="pull-right">
                          {!settings[name] ? (
                            <Uploader
                              className="btn-success btn-sm pull-right"
                              successHandler={uploadFilesHandler(name)}
                              imagesOnly="true"
                              multiple={false}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div> /*                    
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8  my-auto">
                        <SwitchValue value={settings[name]} name={name} onChange={changeSettings} />
                      </div>
                    </div>
                    */
                  );
                })}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

export default connect(null, {
  getSettings: api.get(apiUrls.settings),
  saveSettings: api.patch(apiUrls.settings),
})(Settings);
