/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";
import { Table } from "reactstrap";

import { Link, useRouteMatch, useParams } from "react-router-dom";
import moment from "moment";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FloatCustomers } from "components/special/customers";

import { transactionInfo } from "./index";
import { Pager, Sorter, Finder, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { infoLink as customerLink } from "views/admin/Customers/init";
// import { infoLink as partnerLink } from "views/admin/Items/init";
import { phoneNumber } from "components/special";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const TransactionsTable = (props) => {
  const intl = useIntl();
  const { url, path } = useRouteMatch();
  const params = useParams();
  const [transactions, setTransactions] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const { envStore } = props;

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search), ...params });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setTransactions(body);
    };
    const onRequest = () => {
      setTransactions({ total: -1 });
    };

    query.limit && props.getTransactions({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>

            <th>
              <FloatCustomers
                _id={query.customerId}
                checkAll={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(customerId) => {
                  setQuery({ ...query, customerId });
                }}
              />
              <IntlMessages id="Payments.customer" />
            </th>

            <th>
              <IntlMessages id="Payments.value" />
            </th>

            <th>
              <IntlMessages id="Payments.currency" />
            </th>

            <th>
              {/* 
              <Finder className="mr-1 d-inline" isActive={query.status}>
                <Finder.select
                  name="status"
                  {...{ query, setQuery }}
                  source={_.get(envStore, "payments.statuses")}
                  mask="Payments.status"
                />
              </Finder>
               */}

              <IntlMessages id="Payments.status" />
            </th>

            <th>
              <Finder.search {...{ query, url }} />
            </th>
          </tr>
        </thead>
        {transactions.total < 0 && <TableLoading />}
        <tbody>
          {_.map(transactions.data, (transaction, key) => {
            const { partner, customerId, customer } = transaction;

            return (
              <tr key={key}>
                <td>
                  {moment(transaction.createdAt).format(
                    intl.formatMessage({ id: "dateTimeFormat" })
                  )}
                  {/* 
                  <Link to={transactionInfo(transaction)}>
                    {moment(transaction.createdAt).format(
                      intl.formatMessage({ id: "dateTimeFormat" })
                    )}
                  </Link>
                    */}
                </td>

                <td>
                  <div>
                    <Link to={customerLink({ _id: customerId })}>{customer.email?.value}</Link>
                  </div>
                </td>

                <td>{transaction.value}</td>
                <td>{transaction.currencyName}</td>
                <td>
                  <IntlMessages id={`Payments.status.${transaction.status}`} />
                  {/* <IntlMessages id={`Payments.status.${transaction.status}`} /> */}
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={transactions.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getTransactions: api.get(apiUrls.payments),
})(TransactionsTable);
