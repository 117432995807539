/** модальное окно для выбора категорий в требуемых случаях */
import _ from "lodash";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";

import IntlMessages from "util/intlMessages";

import { LoadMore, TableLoading } from "components/standart";

const FloatModal = (props) => {
  const {
    checkAll,
    successHandler = new Function(),
    showTitle = checkAll,
    showCount = true,
    multi,
    params,
  } = props;

  const _id = useRef([]);

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [selected, setSelected] = useState({ total: -1 });
  const [data, setData] = useState({ total: -1 });

  const [searchQuery, setSearchQuery] = useState({ offset: 0 });

  const [isLoading, setLoading] = useState(false);

  /* обновление списка идентификаторов на основании списка выделенных чекбоксов */
  const checkboxesRef = useRef([]);
  const refreshCheckedId = () => {
    const checkedId = [];
    _.map(checkboxesRef.current, ({ current }) => {
      if (current && current.checked) checkedId.push(current.value);
    });
    _id.current = checkedId;
    if (props.fastConfirm && _.size(_id.current) > 0) {
      successHandler(_id.current);
      setIsOpen(false);
    }
  };

  const timeout = useRef(null);
  useEffect(() => {
    if (isOpen) {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        loadSelected(_id.current);
        searchData();
      }, 700);
    }
  }, [searchQuery]);

  useEffect(() => {
    const _id = _.split(props._id, ",").map(_.trim);
    loadSelected(_id);
  }, [props._id]);

  const loadSelected = (_id) => {
    const res = {
      onSuccess(body) {
        setLoading(false);
        setSelected(body);
      },
      onFailire() {
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
      },
    };
    const query = { _id: _.size(_id) ? _id : null, everything: true };

    props.getData({ params, query }, { ...res });
  };

  useEffect(() => {
    if (isOpen) {
      setSearchQuery({ ...searchQuery, ...props.query, everything: true });
      _id.current = _.split(props._id, ",").map(_.trim);
    } else {
      checkboxesRef.current = [];
      _id.current = [];
      setSearchQuery({});
    }
  }, [isOpen]);

  const limit = 30;
  const searchData = (query = {}, append = false) => {
    const res = {
      onSuccess(body) {
        if (append) {
          body.data = _.concat(data.data || [], body.data);
        }
        setLoading(false);
        setData(body);
      },
      onFailire() {
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
      },
    };
    Object.assign(query, {
      skipId: [..._id.current, ...(props.skipId || [])],
      ..._.omit(searchQuery, "offset"),
      // ...props.query,
    });
    _.defaults(query, { limit, offset: 0, order: "-createdAt" });
    props.getData({ params, query }, res);
  };

  const TableBody = ({ data, total }) => {
    const TableTr = ({ elem }) => {
      const checkboxRef = useRef(null);
      checkboxesRef.current.push(checkboxRef);

      const clickHandler = (event) => {
        const { current } = checkboxRef;
        current.checked = !current.checked;
        refreshCheckedId();
      };
      const type = checkAll || multi ? "checkbox" : "radio";
      const isCheckbox = type === "checkbox";
      return (
        <tr>
          <td>
            <div className={`pretty ${isCheckbox ? "p-icon" : "p-default p-round"}`}>
              <input
                type={type}
                name="_id"
                value={elem._id}
                defaultChecked={_.indexOf(_id.current, elem._id) >= 0}
                ref={checkboxRef}
                onChange={refreshCheckedId}
              />
              <div className={`state ${isCheckbox ? "" : "p-info"}`}>
                {isCheckbox && <i className={`icon fa fa-check text-info`} />}
                <label />
              </div>
            </div>
          </td>
          {props.row(elem, clickHandler, { searchData, searchQuery })}
        </tr>
      );
    };

    return (
      <tbody>
        {_.map(data, (elem, index) => {
          return (
            <Fragment key={index}>
              <TableTr {...{ elem }} />
            </Fragment>
          );
        })}
        {_.size(data) < total && (
          <tr>
            <td colSpan="99" className="text-center">
              <LoadMore
                onClick={() => searchData({ ...searchQuery, offset: _.size(data) }, true)}
              />
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  return (
    <>
      {props.buttonComponent ? (
        props.buttonComponent({ selected, toggleModal })
      ) : (
        <button
          type="button"
          disabled={props.disabled}
          className={`btn ${selected.total > 0 ? "c-info" : "btn-secondary"} ${props.className}`}
          onClick={toggleModal}
        >
          {props.intro && <span className="mr-1">{props.intro}</span>}

          {showTitle &&
            selected.total > 0 &&
            _.map(selected.data, (elem, key) => {
              return (
                <span key={key} className="mr-1">
                  {_.isFunction(props.title) ? props.title(elem) : elem.title || elem.name}
                </span>
              );
            })}

          {showCount && selected.total > 0 && <span className="mx-1">{selected.total}</span>}

          <i className={`fa ${props.icon || "fa-search-plus"}`} />
        </button>
      )}

      {isOpen && (
        <Modal isOpen={isOpen} size={props.size || "md"} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{props.header}</ModalHeader>
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    {checkAll && false && (
                      <div className="pretty p-icon">
                        <input type="checkbox" />
                        <div className="state p-default">
                          <i className="icon fas fa-check"></i>
                          <label>&nbsp;</label>
                        </div>
                      </div>
                    )}
                  </th>

                  {props.head ? (
                    props.head(searchData, searchQuery, setSearchQuery)
                  ) : (
                    <th colSpan="99" />
                  )}
                </tr>
              </thead>

              {props.body ? (
                props.body(data, {
                  _id,
                  checkboxesRef,
                  refreshCheckedId,
                  checkAll,
                  multi,
                })
              ) : (
                <>
                  <TableBody {...selected} />
                  <TableBody {...data} />
                  {isLoading && <TableLoading />}
                </>
              )}

              <tfoot></tfoot>
            </Table>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn c-primary"
              onClick={() => {
                successHandler(_id.current);
                setIsOpen(false);
              }}
            >
              <IntlMessages id="ok" />
            </button>

            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                _id.current = [];
                successHandler(_id.current);
                setIsOpen(false);
              }}
            >
              <i className="fa fa-ban" />
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default FloatModal;
