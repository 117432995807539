import _ from "lodash";
import React, { useState, useRef, useMemo } from "react";
import Sidebar from "components/sidebar/Sidebar";
import HorizontalSidebar from "components/horizontalsidebar/HorizontalSidebar";
import ThemeSetting from "components/themesetting/ThemeSetting";
import dashboardRoutes from "routes/dashboardRoutes";
import clocloRoutes from "routes/adminRoutes";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import themeActions from "redux/themeChanger/actions.js";
import settingactions from "redux/themeSettings/actions";
import AppLocale from "languageProvider";
import { Button } from "reactstrap";
import { drawerWidth, miniDrawerWidth, themeSettingDrawerWidth } from "helper/constant";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { ProtectedRoute } from "./../routes/ProtectedRoute";
import GlobalWrapper from "./global.style";
import LayoutSettings from "components/layoutsetting/LayoutSettings";
const { changeTheme } = themeActions;
const { sidebarMini } = settingactions;

const DashboardLayout = (props) => {
  const sessionStore = useSelector((state) => state.api.session);

  const adminMenu = () => {
    const result = [
      {
        routepath: "/dashboard",
        iconClass: "fa fa-chart-line",
        name: "Dashboard",
      },

      {
        routepath: "/contents",
        iconClass: "fa fa-list",
        name: "Contents",
        child: [
          {
            listname: "Contents.DailyQuotes",
            routepath: "/contents/dailyQuotes",
          },
          {
            listname: "Contents.DailyQuotesCategories",
            routepath: "/contents/dailyQuotesCategories",
          },
          {
            listname: "Contents.DailyQuotesTopics",
            routepath: "/contents/dailyQuotesTopics",
          },
          {
            listname: "Contents.AstroSigns",
            routepath: "/contents/astroSigns",
          },
          {
            listname: "Contents.Celebrities",
            routepath: "/contents/celebrities",
          },
          {
            listname: "Contents.AstroOverlaps",
            routepath: "/contents/astroOverlaps",
          },
          {
            listname: "Contents.Gradients",
            routepath: "/contents/gradients",
          },
          {
            listname: "Contents.GradientsTexts",
            routepath: "/contents/gradientsTexts",
          },
          {
            listname: "Contents.Preferences",
            routepath: "/contents/preferences",
          },
          {
            listname: "Contents.AstroWeeks",
            routepath: "/contents/astroWeeks",
          },
          {
            listname: "Countries",
            routepath: "/contents/countries",
          },
          {
            listname: "Locales",
            routepath: "/contents/locales",
          },
          {
            listname: "Marketplaces",
            routepath: "/contents/marketplaces",
          },
        ],
      },
      {
        routepath: "/items",
        iconClass: "fa fa-box",
        name: "Items",
        child: [
          {
            listname: "Items.List",
            routepath: "/items/list",
          },
          {
            listname: "Items.Categories",
            routepath: "/items/categories",
          },
          {
            listname: "Items.Types",
            routepath: "/items/types",
          },
          {
            listname: "Items.Emits",
            routepath: "/items/emits",
          },
        ],
      },
      {
        routepath: "/customers",
        iconClass: "fa fa-clipboard-user",
        name: "Customers",
      },
      // {
      //   routepath: "/orders",
      //   iconClass: "fa fa-trees",
      //   name: "Orders",
      // },
      {
        routepath: "/payments",
        iconClass: "fa fa-money-bill",
        name: "Payments.Transactions",
        child: [
          {
            listname: "Payments",
            routepath: "/payments/transactions",
          },
          {
            listname: "Payments.Receipts",
            routepath: "/payments/receipts",
          },
          {
            listname: "Payments.ReceiptsServices",
            routepath: "/payments/receipts_services",
          },
          {
            listname: "Payments.PaymentsServices",
            routepath: "/payments/services",
          },
          {
            listname: "Payments.Currencies",
            routepath: "/payments/currencies",
          },
        ],
      },

      {
        routepath: "/users",
        iconClass: "fa fa-users",
        name: "Users",
      },
      {
        routepath: "/www",
        iconClass: "fa fa-globe",
        name: "Www",
        child: [
          {
            listname: "Www.Settings",
            routepath: "/www/settings",
          },
          {
            listname: "Www.Landing",
            routepath: "/www/landing",
          },
          {
            listname: "Www.Feedbacks",
            routepath: "/www/feedbacks",
          },
          {
            listname: "Www.Faq",
            routepath: "/www/faq",
          },
          {
            listname: "Www.Contents",
            routepath: "/www/contents",
          },
          {
            listname: "Www.Meta",
            routepath: "/www/meta",
          },
        ],
      },
      {
        routepath: "/special",
        iconClass: "fa fa-cogs",
        name: "Special",
        child: [
          {
            listname: "Special.Translates",
            routepath: "/special/translates",
          },
          {
            listname: "Special.DemoCustomers",
            routepath: "/special/demo_customers",
          },
        ],
      },
    ];
    /*
    const $path = _.keyBy(sessionStore.routes, "$.menu.routepath");

    _.map(clocloRoutes, ({ path }) => {
      if ($path[path]) {
        result.push($path[path].$.menu);
      }
    });
    */

    return result;
  };

  const [mini, setMini] = useState(
    props.themeSetting.sidebarMiniValue ? props.themeSetting.sidebarMiniValue === "on" : false
  );
  const [themeDrawer, setThemeDrawer] = useState(true);
  const [layoutSettingDrawer, setLayoutSettingDrawer] = useState(true);
  const [statedrawerWidth] = useState(drawerWidth);
  const [stateminiDrawerWidth, setStateminiDrawerWidth] = useState(miniDrawerWidth);
  const mainPanel = useRef(null);
  const scrollbars = useRef(null);

  useMemo(() => {
    if (scrollbars && scrollbars.current) {
      scrollbars.current.scrollToTop(0);
    }
  }, []);

  useMemo(() => {
    setMini(props.themeSetting.sidebarMiniValue === "on");
  }, [props.themeSetting.sidebarMiniValue]);

  let mainPanelWidth;

  const { layoutTheme, locale, themeSetting } = props;
  const currentAppLocale = AppLocale[locale];

  let routeArrowIcon = {
    backgroundColor: props.sidebarTheme.backgroundColor,
    color: props.sidebarTheme.textColor,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    position: "fixed",
    zIndex: 1,
    fontSize: "18px",
    padding: "4px 5px",
  };

  if (themeSetting.layout === "vertical") {
    mainPanelWidth = {
      width: mini ? `calc(100% - ${miniDrawerWidth})` : `calc(100% - ${drawerWidth})`,
      backgroundColor: layoutTheme.backgroundColor,
    };
  } else if (themeSetting.layout === "horizontal") {
    mainPanelWidth = {
      width: "100%",
      backgroundColor: layoutTheme.backgroundColor,
    };
  }

  const drawerMiniMethod = () => {
    if (mini) {
      setMini(false);
      props.sidebarMini("off");
    } else {
      setMini(true);
      props.sidebarMini("on");
    }
  };

  const themeSettingDrawer = () => {
    if (themeDrawer) {
      setThemeDrawer(false);
    } else {
      setThemeDrawer(true);
    }
  };

  const toggleLayoutSettingDrawer = () => {
    setLayoutSettingDrawer(!layoutSettingDrawer);
  };

  const mouseEnter = () => {
    if (mini) {
      setStateminiDrawerWidth(drawerWidth);
    }
  };

  const mouseLeave = () => {
    if (mini) {
      setStateminiDrawerWidth(miniDrawerWidth);
    }
  };

  const closeDrawer = () => {
    setMini(true);
  };
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <GlobalWrapper {...props}>
        {themeSetting.layout === "vertical" && (
          <Sidebar
            mini={mini}
            drawerWidth={statedrawerWidth}
            miniDrawerWidth={stateminiDrawerWidth}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            closeDrawer={() => closeDrawer}
            {...props}
            adminMenu={adminMenu()}
          />
        )}
        {themeSetting.layout === "horizontal" && (
          <div className="hor_mobile_sidebar">
            <Sidebar
              mini={mini}
              drawerWidth={statedrawerWidth}
              miniDrawerWidth={stateminiDrawerWidth}
              onMouseEnter={mouseEnter}
              onMouseLeave={mouseLeave}
              closeDrawer={() => closeDrawer}
              {...props}
              adminMenu={adminMenu()}
            />
          </div>
        )}

        <ThemeSetting
          mini={themeDrawer}
          drawerWidth={themeSettingDrawerWidth}
          miniDrawerWidth={stateminiDrawerWidth}
          closeSettingDrawer={themeSettingDrawer}
          {...props}
        />

        <LayoutSettings
          mini={layoutSettingDrawer}
          drawerWidth={themeSettingDrawerWidth}
          miniDrawerWidth={stateminiDrawerWidth}
          closeSettingDrawer={toggleLayoutSettingDrawer}
          {...props}
        />

        <div id="main-panel" className="main-panel flex-y" ref={mainPanel} style={mainPanelWidth}>
          <div>
            {themeSetting.layout === "horizontal" && (
              <HorizontalSidebar
                {...props}
                adminMenu={adminMenu()}
                drawerMiniMethod={drawerMiniMethod}
                layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
              />
            )}
            {themeSetting.layout === "vertical" &&
              props.themeSetting.toolbarDisplayValue === "show" && (
                <Header
                  drawerMiniMethod={drawerMiniMethod}
                  mini={mini}
                  drawerWidth={statedrawerWidth}
                  miniDrawerWidth={stateminiDrawerWidth}
                  layoutSettingDrawerToggle={toggleLayoutSettingDrawer}
                  {...props}
                />
              )}
          </div>
          {/* 
                    <Button
                        className="setting-button"
                        onClick={themeSettingDrawer}
                        style={{
                            backgroundColor: "white",
                            color: "rgba(0,0,0,.87)",
                        }}
                    >
                        <i className="fas fa-cogs" />
                    </Button>
                     */}

          {themeSetting.toolbarDisplayValue !== "show" && (
            <button
              style={routeArrowIcon}
              className="c-btn mini-drawer-menu-icon-hide-topbar"
              onClick={drawerMiniMethod}
            >
              <i className="fas fa-bars" />
            </button>
          )}

          {/* Route Layout Start*/}
          <div
            id="main-container"
            className="route-height flex-1 overflow-auto pb-5"
            style={
              themeSetting.toolbarDisplayValue === "show"
                ? {
                    background: layoutTheme.backgroundColor,
                  }
                : {
                    background: layoutTheme.backgroundColor,
                  }
            }
          >
            <Switch>
              {/* <ProtectedRoute {...props}> */}

              {process.env.NODE_ENV !== "production" &&
                dashboardRoutes.map((prop, key) => {
                  return <Route exact path={prop.path} component={prop.component} key={key} />;
                })}

              {clocloRoutes.map((prop, key) => {
                return (
                  <Route
                    // exact
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}

              <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
              {/* </ProtectedRoute> */}
            </Switch>
          </div>

          {/* Route Layout Finish*/}
          {/* 
                    <div>
                        {themeSetting.footerDisplayValue === "show" && (
                            <Footer
                                {...props}
                                mini={mini}
                                drawerWidth={statedrawerWidth}
                                miniDrawerWidth={stateminiDrawerWidth}
                            />
                        )}
                    </div>
                    */}
        </div>
      </GlobalWrapper>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  // console.log ('map state to props', {state})
  return {
    ...state.themeChanger,
    LanguageSwitcher: state.LanguageSwitcher,
    locale: state.LanguageSwitcher.language.locale,

    /* пережиток первичной конструкции, потом возможно надо будет удалить
        authData: {
            token: state.auth.accessToken,
            isLogin: state.auth.isLogin
        },
        // */
    themeSetting: {
      toolbarAlignValue: state.themeSetting.toolbarAlignValue,
      footerAlignValue: state.themeSetting.footerAlignValue,
      sidebarDisplayValue: state.themeSetting.sidebarDisplayValue,
      toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
      footerDisplayValue: state.themeSetting.footerDisplayValue,
      sidebarTransParentValue: state.themeSetting.sidebarTransParentValue,
      transparentImage: state.themeSetting.transparentImage,
      activeLinkStyle: state.themeSetting.activeLinkStyle,
      sidebarMiniValue: state.themeSetting.sidebarMiniValue,
      layout: state.themeSetting.layout,
      sidebarTransParentActiveBack: state.themeSetting.sidebarTransParentActiveBack,
      sidebarTransParentActiveColor: state.themeSetting.sidebarTransParentActiveColor,
    },
  };
};

export default connect(mapStateToProps, {
  changeTheme,
  sidebarMini,
})(DashboardLayout);
