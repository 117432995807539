export const apiUrls = {
  dailyQuotes: "/api/contents/daily_quotes",
  dailyQuotesInfo: "/api/contents/daily_quotes/quote_{quote_id}",
  dailyQuotesCategories: "/api/contents/daily_quotes_categories",
  dailyQuotesCategoriesPositions: "/api/contents/daily_quotes_categories/positions",
  dailyQuotesCategoriesInfo: "/api/contents/daily_quotes_categories/category_{category_id}",
  dailyQuotesTopics: "/api/contents/daily_quotes_topics",
  dailyQuotesTopicsInfo: "/api/contents/daily_quotes_topics/topic_{topic_id}",
  preferences: "/api/contents/preferences",
  preferencesPositions: "/api/contents/preferences/positions",
  preferencesInfo: "/api/contents/preferences/preference_{preference_id}",
  astroSigns: "/api/contents/astro_signs",
  astroSignsInfo: "/api/contents/astro_signs/sign_{sign_id}",
  celebrities: "/api/contents/celebrities",
  celebritiesInfo: "/api/contents/celebrities/celebrity_{celebrityId}",
  astroOverlaps: "/api/contents/astro_overlaps",
  astroOverlapsInfo: "/api/contents/astro_overlaps/overlap_{overlap_id}",
  astroOverlapsGradients: "/api/contents/overlaps_gradients",
  astroOverlapsGradientsInfo: "/api/contents/overlaps_gradients/gradient_{gradient_id}",
  astroOverlapsGradientsTexts: "/api/contents/overlaps_gradients_texts",
  astroOverlapsGradientsTextsInfo: "/api/contents/overlaps_gradients_texts/text_{text_id}",
  astroWeeks: "/api/contents/astro_weeks",
  astroWeeksInfo: "/api/contents/astro_weeks/week_{week_id}",
  countries: "/api/contents/countries",
  countriesInfo: "/api/contents/countries/country_{countryId}",
  contriesPositions: "/api/contents/countries/positions",
  locales: "/api/contents/locales",
  localesInfo: "/api/contents/locales/locale_{localeId}",
  localesPositions: "/api/contents/locales/positions",
  marketplaces: "/api/contents/marketplaces",
  marketplacesInfo: "/api/contents/marketplaces/marketplace_{marketplaceId}",
  marketplacesPositions: "/api/contents/marketplaces/positions",
};
